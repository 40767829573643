<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <h1>Caricamento orari di lavoro</h1>
                        <p style="padding:20px;text-transform:uppercase;" v-if="saveMessage" 
                            :class="{'bg-success text-white': saveSuccess, 'bg-danger text-white': !saveSuccess}">
                            {{ saveMessage }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form @submit.prevent="saveSchedule" class="p-4 border rounded">
                            <div class="mb-3">
                                <label for="employee" class="form-label">Seleziona Dipendente</label>
                                <select v-model="form.id_user" id="employee" class="form-select" required>
                                    <option v-for="user in employees" :key="user.value" :value="user.value">
                                        {{ user.label }}
                                    </option>
                                </select>
                            </div>
                            
                            <div v-for="(day, index) in weekDays" :key="index" class="mb-3">
                                <label class="form-label">{{ day }}</label>
                                <div class="d-flex">
                                    <input type="time" v-model="form.schedule[index].start_time" class="form-control me-2" />
                                    <input type="time" v-model="form.schedule[index].end_time" class="form-control" />
                                </div>
                            </div>

                            <button type="submit" class="btn btn-primary w-100">Salva Orari</button>
                        </form>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>
    <div v-else-if="accessDeniedMessage">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <p class="text-danger text-center">{{ accessDeniedMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>
  
<script>
    import { Device } from '@capacitor/device';
    import studioMixin from '@/mixins/studioMixin';
    import AppHeader from '@/components/AppHeader.vue';  
    import AppFooter from '@/components/AppFooter.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    
    export default {
        mixins: [studioMixin],
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
        },
        data() {
            return {
                isLoggedIn: false,
                isMobile: false,
                accessDeniedMessage: '', // Messaggio per accesso negato
                form: {
                    id_user: null,
                    schedule: Array(7).fill().map(() => ({ start_time: '', end_time: '' })),
                },
                employees: [], // Lista di utenti associati allo studio
                saveMessage: '',
                saveSuccess: false,
                weekDays: ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'],
            };
        },
        async created() {
            const info = await Device.getInfo();
            this.isMobile = info.platform === 'ios' || info.platform === 'android';
        },
        mounted() {
            this.checkLogin();
            this.form.id_studio = this.getStudioIdFromToken();
            this.getEmployees();
        },
        methods: {
            checkLogin() {
                const token = localStorage.getItem('token');
                this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const userRole = decodedToken.id_role;
                if (userRole !== 1 && userRole !== 2) {
                    //alert('Accesso negato: Non hai i permessi per accedere a questa pagina.');
                    //this.$router.push('/dashboard');
                    this.accessDeniedMessage = 'Accesso negato: Non hai i permessi per accedere a questa pagina.';
                    this.isLoggedIn = false; 
                    return;
                }
            },
            async getEmployees() {
                try {
                    const response = await fetch(`https://toothtime.it/api/getStudioEmployees/${this.getStudioIdFromToken()}`, {
                        method: 'GET',
                        headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error("Errore nel recupero dei dati.");
                    }
                    this.employees = await response.json();
                } catch (error) {
                    console.error("Errore:", error);
                }
            },
            async saveSchedule() {
                try {
                    const response = await fetch('https://toothtime.it/api/userSchedules', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.form),
                    });
                    if (!response.ok) {
                        throw new Error('Errore durante il salvataggio.');
                    }
                    this.saveMessage = 'Orari salvati con successo!';
                    this.saveSuccess = true;
                } catch (error) {
                    console.error(error);
                    this.saveMessage = 'Salvataggio fallito.';
                    this.saveSuccess = false;
                }
            },
        },
    };
</script>