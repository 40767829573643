<template>
    <div v-if="isLoggedIn">
      <AppSidebar />
      <div class="wrapper d-flex flex-column min-vh-100">
        <AppHeader />
        <div class="container-fluid">
          <h1 class="text-center">Bacheca Messaggi</h1>
  
          <div v-if="messages.length" class="mt-3">
            <ul class="list-group">
              <li v-for="message in messages" :key="message.id_message" class="list-group-item">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>{{ formatDate(message.send_date) }}</strong>
                    <p>{{ message.message_text }}</p>
                  </div>
                  <button 
                    v-if="!message.read_status" 
                    @click="markAsRead(message.id_message)" 
                    class="btn btn-sm btn-primary"
                  >
                    <CIcon icon="cil-check" />
                  </button>
                  <span v-else class="text-primary">
                    <!--<CIcon icon="cil-check" /> ICONA SE GIÁ LETTO-->
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <p v-else class="text-center">Nessun messaggio disponibile.</p>
        </div>
        <AppFooter />
      </div>
    </div>
    <div v-else>
      <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
  </template>
  
  <script>
  import studioMixin from '@/mixins/studioMixin';
  import AppHeader from '@/components/AppHeader.vue';  
  import AppFooter from '@/components/AppFooter.vue';
  import AppSidebar from '@/components/AppSidebar.vue';
  
  export default {
    mixins: [studioMixin],
    components: {
      AppHeader,
      AppFooter,
      AppSidebar,
    },
    data() {
      return {
        isLoggedIn: false,
        messages: [],
      };
    },
    mounted() {
      this.checkLogin();
      this.fetchAllMessages();
    },
    methods: {
      checkLogin() {
        const token = localStorage.getItem('token');
        this.isLoggedIn = !!token;
        if (!this.isLoggedIn) {
          this.$router.push('/login');
        }
      },
  
      async fetchAllMessages() {
        try {
          const response = await fetch('https://toothtime.it/api/getAllMessages', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Errore durante il recupero dei messaggi.');
          }
  
          this.messages = await response.json();
        } catch (error) {
          console.error('Errore nel recupero dei messaggi:', error);
        }
      },
  
      async markAsRead(id_message) {
        try {
          const response = await fetch(`https://toothtime.it/api/markMessageAsRead/${id_message}`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });
  
          if (!response.ok) {
            throw new Error('Errore durante la conferma di lettura.');
          }
  
          // Aggiorna lo stato del messaggio come letto
          this.messages = this.messages.map(msg => 
            msg.id_message === id_message ? { ...msg, read_status: 1 } : msg
          );
        } catch (error) {
          console.error('Errore nel confermare la lettura del messaggio:', error);
        }
      },
  
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });
      }
    },
  };
  </script>
  