<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <h1>Report Ore</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form @submit.prevent="generateReport">
                            <!-- Data inizio -->
                            <div class="mb-3">
                                <label for="startDate" class="form-label">Data inizio *</label>
                                <div class="input-group">
                                    <input 
                                        type="date" 
                                        id="startDate" 
                                        class="form-control" 
                                        v-model="startDate"
                                    >
                                </div>
                            </div>

                            <!-- Data fine -->
                            <div class="mb-3">
                                <label for="endDate" class="form-label">Data fine *</label>
                                <div class="input-group">
                                    <input 
                                        type="date" 
                                        id="endDate" 
                                        class="form-control" 
                                        v-model="endDate"
                                    >
                                </div>
                            </div>

                            <!-- Tipo utente -->
                            <div class="mb-3">
                                <label for="userType" class="form-label">Tipo utente *</label>
                                <select 
                                    id="userType" 
                                    class="form-select" 
                                    v-model="userType"
                                >
                                    <option value="" disabled>Seleziona tipo</option>
                                    <option value="3">Dipendenti</option>
                                    <option value="4">Collaboratori</option>
                                </select>
                            </div>

                            <!-- Arrotondamento ore totale -->
                            <div class="mb-3">
                                <label for="arrIn" class="form-label">
                                    Arrotondamento minuti (ingresso e uscita) 
                                    <CIcon 
                                        icon="cil-alarm" 
                                        ref="iconIn" 
                                        @mouseover="showTooltip('in')" 
                                        @mouseleave="hideTooltip('in')" 
                                    />
                                    <div ref="tooltipIn" class="tooltip-text" v-if="visibleIn">Inserisci i minuti per l'arrotondamento delle ore in entrata e uscita</div>
                                </label>
                                <div class="input-group">
                                    <input 
                                        type="text" 
                                        id="arrIn"
                                        placeholder="0"
                                        class="form-control" 
                                        v-model="arrIn"
                                    >
                                </div>
                            </div>

                            <!--
                            <div class="mb-3">
                                <label for="arrIn" class="form-label">
                                    Arrotondamento ingresso 
                                    <CIcon 
                                        icon="cil-alarm" 
                                        ref="iconIn" 
                                        @mouseover="showTooltip('in')" 
                                        @mouseleave="hideTooltip('in')" 
                                    />
                                    <div ref="tooltipIn" class="tooltip-text" v-if="visibleIn">Inserisci i minuti per l'arrotondamento delle ore in entrata</div>
                                </label>
                                <div class="input-group">
                                    <input 
                                        type="text" 
                                        id="arrIn"
                                        placeholder="0"
                                        class="form-control" 
                                        v-model="arrIn"
                                    >
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="arrOut" class="form-label">
                                    Arrotondamento uscita
                                    <CIcon 
                                        icon="cil-alarm" 
                                        ref="iconOut" 
                                        @mouseover="showTooltip('out')" 
                                        @mouseleave="hideTooltip('out')" 
                                    />
                                    <div ref="tooltipOut" class="tooltip-text" v-if="visibleOut">Inserisci i minuti per l'arrotondamento delle ore in uscita</div>
                                </label>
                                <div class="input-group">
                                    <input 
                                        type="text" 
                                        id="arrOut"
                                        placeholder="0"
                                        class="form-control" 
                                        v-model="arrOut"
                                    >
                                </div>
                            </div>
                            -->

                            <!-- Pulsante di invio -->
                            <button type="submit" class="btn btn-primary w-100">Scarica report</button>
                        </form>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>
    <div v-else-if="accessDeniedMessage">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <p class="text-danger text-center">{{ accessDeniedMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>

    
<script>
    //import { CIcon } from '@coreui/icons-vue';
    import { Device } from '@capacitor/device';
    import AppHeader from '@/components/AppHeader.vue';  
    import AppFooter from '@/components/AppFooter.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    import { createPopper } from '@popperjs/core';
    
    export default {
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
        },
        data() {
            return {
                isLoggedIn: false,
                isMobile: false,
                startDate: '', // Data in formato YYYY-MM-DD
                endDate: '', // Data in formato YYYY-MM-DD
                arrIn: 0,
                arrOut: 0,
                userType: '', // Tipo di utente selezionato
                accessDeniedMessage: '', // Messaggio per accesso negato
                visibleIn: false,
                visibleOut: false,
                popperInstanceIn: null,
                popperInstanceOut: null,
            };
        },
        async created() {
            const info = await Device.getInfo();
            this.isMobile = info.platform === 'ios' || info.platform === 'android';
        },
        mounted() {
            this.checkLogin();
        },
        methods: {
            checkLogin() {
                const token = localStorage.getItem('token');
                this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const userRole = decodedToken.id_role;
                if (userRole !== 1 && userRole !== 2) {
                    //alert('Accesso negato: Non hai i permessi per accedere a questa pagina.');
                    //this.$router.push('/dashboard');
                    this.accessDeniedMessage = 'Accesso negato: Non hai i permessi per accedere a questa pagina.';
                    this.isLoggedIn = false; 
                    return;
                }
            },
            async generateReport() {
                // Temp logs
                console.log('Genera il report con i seguenti dati:');
                console.log('Data inizio:', this.startDate);
                console.log('Data fine:', this.endDate);
                console.log('Tipo utente:', this.userType);
                console.log('Arrotondamento entrata:', this.arrIn);
                //console.log('Arrotondamento uscita:', this.arrOut);

                if (!this.startDate || !this.endDate || !this.userType) {
                    alert('Compila tutti i campi obbligatori prima di scaricare il report!');
                    return;
                }

                try {
                    const queryParams = new URLSearchParams({
                        startDate: this.startDate,
                        endDate: this.endDate,
                        userType: this.userType,
                        arrIn: this.arrIn,
                        //arrOut: this.arrOut,
                    }).toString();

                    const token = localStorage.getItem('token'); // Recupera il token di autenticazione

                    const response = await fetch(`https://toothtime.it/api/reports/hours?${queryParams}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    });

                    if (!response.ok) throw new Error('Errore durante la generazione del report.');

                    // Generazione e download del file CSV
                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'report_ore.csv'; // Nome del file temporaneo
                    a.click();
                    window.URL.revokeObjectURL(url);

                } catch (error) {
                    console.error('Errore:', error);
                    alert('Errore durante il download del report.');
                }
            },
            showTooltip(type) {
                if (type === 'in') {
                    this.visibleIn = true;
                    this.$nextTick(() => {
                    if (!this.popperInstanceIn) {
                        this.popperInstanceIn = createPopper(this.$refs.iconIn.$el || this.$refs.iconIn, this.$refs.tooltipIn, {
                            placement: 'top',
                            modifiers: [
                                { name: 'offset', options: { offset: [0, 8] } },
                                { name: 'preventOverflow', options: { boundary: 'viewport' } },
                                { name: 'flip', options: { enabled: false } },
                            ],
                        });
                    }
                    });
                } else if (type === 'out') {
                    this.visibleOut = true;
                    this.$nextTick(() => {
                    if (!this.popperInstanceOut) {
                        this.popperInstanceOut = createPopper(this.$refs.iconOut.$el || this.$refs.iconOut, this.$refs.tooltipOut, {
                            placement: 'top',
                            modifiers: [
                                { name: 'offset', options: { offset: [0, 8] } },
                                { name: 'preventOverflow', options: { boundary: 'viewport' } },
                                { name: 'flip', options: { enabled: false } },
                            ],
                        });
                    }
                    });
                }
            },
            hideTooltip(type) {
                if (type === 'in') {
                    this.visibleIn = false;
                    if (this.popperInstanceIn) {
                    this.popperInstanceIn.destroy();
                    this.popperInstanceIn = null;
                    }
                } else if (type === 'out') {
                    this.visibleOut = false;
                    if (this.popperInstanceOut) {
                    this.popperInstanceOut.destroy();
                    this.popperInstanceOut = null;
                    }
                }
            },
        }
    };
</script>

<style>
.tooltip-text {
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  position: absolute;
  transform: translateX(-50%); /* Centro il tooltip */
  white-space: nowrap;
}
</style>