<template>
    <div class="container vh-100 d-flex flex-column justify-content-center form-login">
      <div class="row">
        <div class="col-12">
          <img class="img-fluid" src="../assets/logo_g.png"/>
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="email" class="form-label">Email</label>
              <input 
                type="email" 
                v-model="email" 
                class="form-control" 
                id="email" 
                name="email" 
                autocomplete="username" 
                required 
                @focus="triggerAutoFill"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <!--<input type="password" v-model="password" class="form-control" id="password" autocomplete="password" required />-->
              <div class="input-group">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  class="form-control"
                  id="password"
                  name="password"
                  autocomplete="current-password"
                  required
                />
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="togglePasswordVisibility"
                  tabindex="-1"
                >
                  <span v-if="showPassword">Nascondi</span>
                  <span v-else>Mostra</span>
                </button>
              </div>
            </div>
            <div class="d-grid gap-2">
              <button type="submit" class="btn btn-primary btn-lg">Accedi</button>
            </div>
          </form>
          <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-5">
          <div v-if="isMobile">
            <small>Hai bisogno di supporto? Contattaci<br>toothtime@crazyadv.com</small><br>
          </div>
          <div v-else>
            <small>Hai bisogno di supporto? Contattaci<br>toothtime@crazyadv.com</small><br>
            <a href="https://apps.apple.com/it/app/toothtime/id6740210501"><img src="../assets/apple.svg" class="img-fluid mt-3" alt="Scarica ToothTime su Apple Store"></a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        error: null,
        showPassword: false,
        isMobile: false,
      };
    },
    methods: {
      async login() {
        try {
          const response = await fetch('https://toothtime.it/api/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: this.email, password: this.password }),
          });
  
          if (!response.ok) {
            //throw new Error('Login fallito: ' + response.statusText);
            throw new Error('Login fallito.');
          }
  
          const data = await response.json();
          ////console.log('Token ricevuto:', data.token);
          ////console.log('refreshToken ricevuto:', data.refreshToken);
          localStorage.setItem('token', data.token);
          localStorage.setItem('refreshToken', data.refreshToken);
          //sessionStorage.setItem('token', data.token);
          this.$router.push('/dashboard');
          //window.location.href = '/dashboard';
        } catch (error) {
          this.error = error.message;
        }
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
      triggerAutoFill() {
        if (navigator.credentials) {
          navigator.credentials.get({ password: true }).then(cred => {
            if (cred) {
              this.email = cred.id;
              this.password = cred.password;
            }
          });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 400px;
    margin: auto;
    padding-top: 50px;
  }
  </style>
  