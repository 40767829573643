<template>
  <div v-if="isLoggedIn">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div class="container-fluid text-center">
        <h1>Ciao {{ userFirstName }}, le tue ore mensili lavorate sono:</h1>
        <div v-if="monthlyHours">
          <a href="/module/badge" class="btn btn-badge btn-primary nascosto" style="padding:35px 40px;">
            <strong style="font-size:2rem;">{{ Math.round(monthlyHours.total_hours) }}</strong>
          </a>
          <a href="/module/badgeqr" class="btn btn-badge btn-primary" style="padding:35px 40px;">
            <strong style="font-size:2rem;">{{ Math.round(monthlyHours.total_hours) }}</strong>
          </a>
          <div class="nascosto">
            <h2>Dettaglio Giornaliero:</h2>
            <ul>
              <li v-for="day in monthlyHours.daily_hours" :key="day.date">
                {{ day.date }}: {{ day.hours }} ore
              </li>
            </ul>
          </div>
        </div>
        <p v-else>Caricamento delle ore lavorate...</p>

        <div v-if="unreadMessages.length" class="alert alert-dark mt-3">
          <h5>Messaggi non letti</h5>
          <ul class="list-group">
            <li v-for="message in unreadMessages" :key="message.id_message" class="list-group-item d-flex justify-content-between align-items-center">
              <span>{{ message.message_text }}</span>
              <button @click="markAsRead(message.id_message)" class="btn btn-sm btn-primary"><CIcon icon="cil-check" /></button>
            </li>
          </ul>
        </div>

        <div class="nascosto">
          <h1>Moduli Abilitati</h1>
          <ul v-if="enabledModules.length > 0">
            <li v-for="module in enabledModules" :key="module.id_studio_module">
              {{ module.namefront }}
            </li>
          </ul>
          <p v-else>Nessun modulo abilitato trovato.</p>
        </div>
      </div>
      <AppFooter />
    </div>
  </div>
  <div v-else>
    <p>Devi effettuare il login per accedere a questa pagina.</p>
  </div>
</template>

<script>
//import { CIcon } from '@coreui/icons-vue';
import studioMixin from '@/mixins/studioMixin';
import AppHeader from '@/components/AppHeader.vue';  
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
  mixins: [studioMixin],
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
  },
  data() {
    return {
      isLoggedIn: false,
      enabledModules: [],
      monthlyHours: null,
      userFirstName: '',
      isMobile: false,
      unreadMessages: [],
    };
  },
  mounted() {
    this.checkLogin();
    const studioId = this.getStudioIdFromToken();
    if (studioId) {
      //this.fetchStudioData(studioId);
      this.fetchEnabledModules();
      this.fetchMonthlyHours();
      this.fetchUserDetails();
      this.fetchUnreadMessages();
    }
  },
  methods: {
    checkLogin() {
      const token = localStorage.getItem('token');
      this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
      if (!this.isLoggedIn) {
        this.$router.push('/login');
      }
    },
    async fetchUserDetails() {
      try {
        const response = await fetch('https://toothtime.it/api/user/details', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          console.error('Errore nella risposta API:', errorMessage);
          throw new Error('Errore durante il recupero dei dettagli dell\'utente.');
        }

        const userDetails = await response.json();
        this.userFirstName = userDetails.firstName || 'Utente'; // Imposta il nome utente
      } catch (error) {
        console.error('Errore nel recupero dei dettagli dell\'utente:', error);
      }
    },
    async fetchEnabledModules() {
      //console.log('Token:', localStorage.getItem('token'));
      try {
        const response = await fetch('https://toothtime.it/api/modules/enabled', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          console.error('Risposta del server non OK:', errorMessage);
          throw new Error('Errore durante il recupero dei moduli abilitati.');
        }

        this.enabledModules = await response.json();
        //console.log('Moduli abilitati caricati:', this.enabledModules);
      } catch (error) {
        console.error('Errore:', error);
      }
    },
    async fetchMonthlyHours() {
      try {
        const response = await fetch('https://toothtime.it/api/ore-mensili', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          console.error('Risposta del server non OK:', errorMessage);
          throw new Error('Errore durante il recupero delle ore mensili.');
        }

        this.monthlyHours = await response.json();
        //console.log('Ore mensili caricate:', this.monthlyHours);
      } catch (error) {
        console.error('Errore:', error);
      }
    },
    async fetchUnreadMessages() {
      try {
        const response = await fetch('https://toothtime.it/api/getUnreadMessages', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Errore durante il recupero dei messaggi non letti.');
        }

        this.unreadMessages = await response.json();
      } catch (error) {
        console.error('Errore nel recupero dei messaggi non letti:', error);
      }
    },

    async markAsRead(id_message) {
      try {
        const response = await fetch(`https://toothtime.it/api/markMessageAsRead/${id_message}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Errore durante la conferma di lettura.');
        }

        // Rimuove il messaggio dalla lista dopo la conferma di lettura
        this.unreadMessages = this.unreadMessages.filter(msg => msg.id_message !== id_message);
      } catch (error) {
        console.error('Errore nel confermare la lettura del messaggio:', error);
      }
    },
  },
};
</script>
