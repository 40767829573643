<template>
    <div v-if="isLoggedIn">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <h1>Invio Messaggi Bacheca</h1>
                        <p style="padding:20px;text-transform:uppercase;" v-if="uploadMessage" :class="{'bg-success text-white': uploadSuccess, 'bg-danger text-white': !uploadSuccess}">
                            {{ uploadMessage }}
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <form @submit.prevent="sendMessage" class="p-4 border rounded">
                            <div class="mb-3 nascosto">
                                <label for="message_title" class="form-label">Titolo messaggio</label>
                                <input
                                type="text"
                                id="message_title"
                                v-model="form.message_title"
                                class="form-control"
                                placeholder="Inserisci il titolo del messaggio per le notifiche"
                                />
                            </div>

                            <div class="mb-3">
                                <label for="message_text" class="form-label">Testo messaggio <sup>*</sup></label>
                                <textarea
                                id="message_text"
                                v-model="form.message_text"
                                class="form-control"
                                placeholder="Inserisci il testo del messaggio"
                                required
                                />
                            </div>

                            <div class="mb-3">
                                <label for="expiration_time" class="form-label">Ore massime per obbligo di lettura <sup>*</sup></label>
                                <input
                                type="number"
                                id="expiration_time"
                                v-model="form.expiration_time"
                                class="form-control"
                                placeholder="Ore per la conferma di lettura"
                                min="1"
                                required
                                />
                            </div>

                            <div class="mb-3">
                                <label for="assigned_to" class="form-label">Assegna a (opzionale)</label>
                                <select v-model="form.id_user" id="assigned_to" class="form-select">
                                <option value="">Nessuno (pubblico) - Tutto lo Studio</option>
                                <option v-for="user in employees" :key="user.value" :value="user.value">
                                    {{ user.label }}
                                </option>
                                </select>
                            </div>

                            <button type="submit" class="btn btn-primary w-100">Invia Messaggio</button>
                        </form>
                    </div>
                </div>
            </div>
            <AppFooter />
        </div>
    </div>
    <div v-else-if="accessDeniedMessage">
        <AppSidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <AppHeader />
            <div class="container-fluid">
                <div class="row text-center mb-3">
                    <div class="col-md-12">
                        <p class="text-danger text-center">{{ accessDeniedMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <AppFooter />
    </div>
    <div v-else>
        <p>Devi effettuare il login per accedere a questa pagina.</p>
    </div>
</template>
  
<script>
    //import { CIcon } from '@coreui/icons-vue';
    import { Device } from '@capacitor/device';
    import studioMixin from '@/mixins/studioMixin';
    import AppHeader from '@/components/AppHeader.vue';  
    import AppFooter from '@/components/AppFooter.vue';
    import AppSidebar from '@/components/AppSidebar.vue';
    
    export default {
        mixins: [studioMixin],
        components: {
            AppHeader,
            AppFooter,
            AppSidebar,
        },
        data() {
            return {
                isLoggedIn: false,
                isMobile: false,
                accessDeniedMessage: '', // Messaggio per accesso negato
                form: {
                    id_studio: null, // Impostato con l'ID dello Studio
                    id_user: '', // Destinatario del documento (opzionale)
                    message_title: '',
                    message_text: '',
                    expiration_time: 0,
                },
                file: null, // File selezionato
                employees: [], // Lista di utenti associati allo studio
                uploadMessage: '',
                uploadSuccess: false,
            };
        },
        async created() {
            const info = await Device.getInfo();
            this.isMobile = info.platform === 'ios' || info.platform === 'android';
        },
        mounted() {
            this.checkLogin();
            this.form.id_studio = this.getStudioIdFromToken();
            this.getEmployees();
        },
        methods: {
            checkLogin() {
                const token = localStorage.getItem('token');
                this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
                const decodedToken = JSON.parse(atob(token.split(".")[1]));
                const userRole = decodedToken.id_role;
                if (userRole !== 1 && userRole !== 2) {
                    //alert('Accesso negato: Non hai i permessi per accedere a questa pagina.');
                    //this.$router.push('/dashboard');
                    this.accessDeniedMessage = 'Accesso negato: Non hai i permessi per accedere a questa pagina.';
                    this.isLoggedIn = false; 
                    return;
                }
            },
            async getEmployees() {
                try {
                    const response = await fetch(`https://toothtime.it/api/getStudioEmployees/${this.getStudioIdFromToken()}`, {
                        method: 'GET',
                        headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                        },
                    });
                    if (!response.ok) {
                        throw new Error("Errore nel recupero dei dati.");
                    }
                    this.employees = await response.json();
                } catch (error) {
                    console.error("Errore:", error);
                }
            },
            async sendMessage() {

                //console.log("Dati inviati: ", this.form);

                try {
                    const response = await fetch(`https://toothtime.it/api/sendMessage`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.form),
                    });

                    const result = await response.json();

                    if (response.ok) {
                        this.uploadMessage = "Messaggio inviato con successo!";
                        this.uploadSuccess = true;
                        this.resetForm();
                    } else {
                        throw new Error(result.message || "Errore durante l'invio del messaggio.");
                    }
                } catch (error) {
                    console.error("Errore:", error);
                    this.uploadMessage = error.message;
                    this.uploadSuccess = false;
                }
            },
            resetForm() {
                this.form.message_title = '';
                this.form.message_text = '';
                this.form.expiration_time = 0;
                this.form.id_user = '';
            },
        },
    };
</script>