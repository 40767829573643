<template>
<div v-if="isLoggedIn">
    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100">
    <AppHeader />

    <!-- BLOCCO SE L'UTENTE HA MESSAGGI OBBLIGATORI NON LETTI -->
    <div class="container-fluid text-center" v-if="isBlocked">
        <div class="alert alert-danger mt-4">
            <h4>⚠️ ATTENZIONE!</h4>
            <p>Non puoi timbrare l'entrata o l'uscita finché non avrai letto tutti i messaggi obbligatori!</p>
            <a href="/module/bacheca" class="btn btn-warning">Vai alla Bacheca</a>
        </div>
    </div>

    <div class="container-fluid"
    v-if="!isBlocked && (lastAction == 2 || lastAction == 3 || !lastAction)"
    >
        <div class="row text-center mb-3">
            <div class="col-md-12">
                <h1>In questa pagina potrai timbrare entrata e uscita.</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center flex-wrap">
                    <div v-if="debugMessage" style="background-color: #f8d7da; color: #721c24; padding: 10px;">
                        Debug: {{ debugMessage }}<br>
                    </div>
                    <div id="reader" v-if="showReader" style="width: 300px;"></div><br>
                    <button 
                        v-if="lastAction == 2 || !lastAction" 
                        type="button" 
                        class="btn btn-badge btn-primary" 
                        @click="setBadgeType(1)">Ingresso
                    </button>
                    <button 
                        v-if="lastAction == 3 || lastAction == 4 || lastAction == 5" 
                        type="button" 
                        class="btn btn-badge btn-danger" 
                        @click="setBadgeType(2)">Uscita
                    </button>
                </div>
                <div v-if="message" :class="'alert alert-' + alerttype + ' mt-3'">{{ message }}</div>
                <div class="text-center mt-5">
                    <span><strong>Ultimi ingressi e uscite registrati:</strong><br></span>
                    <span v-for="entry in lastInOut" :key="entry.time">
                        {{ entry.action === 1 ? 'Ingresso' : 'Uscita' }} - {{ new Date(entry.time).toLocaleString() }}<br>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid"
    v-if="!isBlocked && lastAction == 1"
    >
        <div class="row text-center mb-5">
            <div class="col-md-12">
                <h1>Selezionare la durata della pausa pranzo odierna</h1>
            </div>
        </div>
        <div class="row justify-content-center mb-3">
            <div class="col-md-6">
                <select class="form-select" v-model="selectedTime">
                    <option value="0">Nessuna pausa</option>
                    <option value="30">30 minuti</option>
                    <option value="60">60 minuti</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-center flex-wrap">
                    <button type="button" class="btn btn-badge btn-warning" @click="setBadgeType(3)">Conferma Pausa</button>
                </div>
                <div v-if="message" :class="'alert alert-' + alerttype + ' mt-3'">{{ message }}</div>
            </div>
        </div>
    </div>
    <AppFooter />
    </div>
</div>
<div v-else>
    <p>Devi effettuare il login per accedere a questa pagina.</p>
</div>
</template>

<script>
//import { CIcon } from '@coreui/icons-vue';
import { Device } from '@capacitor/device';
import { Html5Qrcode } from 'html5-qrcode';
import AppHeader from '@/components/AppHeader.vue';  
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {
    components: {
        AppHeader,
        AppFooter,
        AppSidebar,
    },
    data() {
        return {
            isLoggedIn: false,
            isMobile: false,
            badgeData: {
                typeaction: null,  // Tipo di timbratura (1 = Entrata, 2 = Uscita, 3 = Pausa)
            },
            message: '',
            alerttype: '',
            lastAction: null,
            lastInOut: null,
            selectedTime: 0,
            showReader: false,
            debugMessage: '',
            isBlocked: false
        };
    },
    async created() {
        const info = await Device.getInfo();
        this.isMobile = info.platform === 'ios' || info.platform === 'android';
        this.fetchLastTimbratura();
        this.fetchLastInOut();
    },
    mounted() {
        this.checkLogin();
        this.checkBlockedUser();
    },
    methods: {
        checkLogin() {
            const token = localStorage.getItem('token');
            this.isLoggedIn = !!token;  // Verifica se l'utente è loggato
            if (!this.isLoggedIn) {
                this.$router.push('/login');
            }
        },
        fetchLastTimbratura() {
            // Recupera l'ultima timbratura dall'API
            fetch('https://toothtime.it/api/ultima-timbratura', {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then(response => response.json())
            .then(data => {
                this.lastAction = data.action;  // 'entrata', 'uscita', o null - inizio o fine pausa pranzo
            })
            .catch(error => console.error('Errore nel recupero dell\'ultima timbratura:', error));
        },
        fetchLastInOut() {
            // Recupera gli ultimi 3 ingressi e uscite
            fetch('https://toothtime.it/api/ultimi-inout', {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                },
            })
            .then(response => response.json())
            .then(data => {
                if (data.actions && data.actions.length > 0) {
                    //console.log(data.actions);
                    this.lastInOut = data.actions;
                } else {
                    console.log('Nessun dato disponibile.');
                    this.lastInOut = [];
                }
            })
            .catch(error => console.error('Errore nel recupero delle ultime giornate:', error));
        },
        setBadgeType(typeaction) {
            this.showReader = true;
            this.badgeData.typeaction = typeaction;
            this.message = '';
            this.alerttype = 'success';
            this.debugMessage = '';

            this.$nextTick(() => {
                if (typeaction === 3) {
                    // Registro pausa senza scansione QR Code
                    this.message = 'Pausa registrata!';
                    this.submitBadge().then(() => {
                        window.location.reload();
                    });
                } else {
                    // Verifica se la scansione è supportata
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                    if (isSafari && window.location.protocol !== 'https:') {
                        this.message = 'Scansione non supportata senza connessione HTTPS su Safari.';
                        return;
                    }
                    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                        this.message = 'Scansione non supportata su questo dispositivo.';
                        return;
                    }

                    // Avvio la scansione del QR Code
                    const html5QrCode = new Html5Qrcode("reader");

                    ////this.debugMessage = 'Scanner avviato';

                    html5QrCode.start(
                        { facingMode: "environment" }, // environment usa la fotocamera posteriore e user front
                        {
                            fps: 10,
                            disableFlip: true,
                            formatsToSupport: ['QR_CODE'], // Supporto solo per i QR Code
                            qrbox: { width: 250, height: 250 }, // Dimensioni dell'area di scansione
                        },
                        (decodedText) => {
                            console.log('QR Code letto:', decodedText);
                            ////this.debugMessage = 'QR Code letto: ' + decodedText;
                            this.message = typeaction === 1 ? 'Timbratura di ingresso registrata!' : 'Timbratura di uscita registrata!';

                            // Verifica il contenuto del QR Code per debug
                            /*
                            try {
                                const decodedData = JSON.parse(decodedText);  // Se il QR code contiene dati JSON
                                ////this.debugMessage = decodedData;
                            } catch (e) {
                                ////this.debugMessage = 'Errore nel parsing del QR code: '+e;
                            }
                            */
                            
                            // Ferma lo scanner solo dopo il submit
                            this.submitBadge(decodedText).then(() => {
                                //window.location.reload();
                                html5QrCode.stop().then(() => {
                                    ////this.debugMessage = 'Scanner fermato con successo.';
                                    this.fetchLastTimbratura();
                                    this.fetchLastInOut();
                                }).catch((err) => {
                                    console.warn('Errore durante lo stop dello scanner:', err);
                                    ////this.debugMessage = 'Errore durante lo stop dello scanner: ' + err;
                                });
                            });
                        },
                        (errorMessage) => {
                            console.warn('Errore durante la scansione:', errorMessage);
                            ////this.debugMessage = 'Errore durante la scansione: '+errorMessage;
                            //html5QrCode.stop();  // Ferma la scansione anche in caso di errore
                        }
                    ).then(() => {
                        ////this.debugMessage = 'Start chiamato';
                    }).catch((err) => {
                        console.error('Errore durante l\'avvio della scansione:', err);
                        ////this.debugMessage = 'Errore durante l\'avvio: ' + err;
                    });
                }
            });
        },
        redirectAfterExit() {
            window.location.href = '/module/badge-break';
        },
        async submitBadge(qrCodeContent = null) {
            if (!this.badgeData.typeaction) {
                this.message = 'Seleziona una timbratura!';
                return;
            }

            ////this.debugMessage = 'QR Code Content: ' + qrCodeContent;

            if (qrCodeContent) {
                try {
                    const response = await fetch('https://toothtime.it/api/verify-token', {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ token: qrCodeContent }),
                    });

                    const result = await response.json();
                    if (!result.valid) {
                        this.alerttype = 'danger';
                        this.message = 'QR Code non valido.';
                        return;
                    }
                } catch (error) {
                    console.error('Errore durante la verifica del token:', error);
                    this.alerttype = 'danger';
                    this.message = 'QR Code non valido.';
                    //this.message = 'QR Code Content: ' + qrCodeContent + ' QR Code non valido: '+error;
                    return;
                }
            }

            try {
                const url = 'https://toothtime.it/api/timbratura';
                const method = 'POST';

                const response = await fetch(url, {
                    method: method,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        typeaction: this.badgeData.typeaction,
                        qrCode: qrCodeContent,
                        selectedTime: this.selectedTime
                    }),
                });

                if (!response.ok) {
                    throw new Error('Errore durante il salvataggio dello studio.');
                }

                this.alerttype = 'success';
                this.message = 'Timbratura registrata con successo!';

            } catch (error) {
                console.error('Errore:', error);
                this.alerttype = 'danger';
                if (error.name === 'TokenExpiredError') {
                    this.message = 'Il QR Code è scaduto. Contatta il supporto.';
                } else {
                    this.message = 'QR Code non valido o errore nella verifica.';
                }
            }
        },
        async checkBlockedUser() {
            try {
                const response = await fetch('https://toothtime.it/api/checkBlockedUser', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Errore nel controllo dello stato di blocco.');
                }

                const result = await response.json();
                this.isBlocked = result.blocked;
            } catch (error) {
                console.error('Errore nel controllo blocco utente:', error);
            }
        }
    }
};
</script>