<template>

  <div class="margine-footer"></div>

    <CFooter class="px-4 nascosto">
      <div class="mr-auto">
        <span class="ms-1">
          Powered with ❤️‍🔥 by 
          <a href="https://www.crazyadv.com/" target="_blank">Crazy Adv Srl</a>
        </span>
        <span class="ms-1">
          &copy; {{ new Date().getFullYear() }} ToothTime v 0.0.2
        </span>
      </div>
    </CFooter>

    <div class="footer-mobile-custom">
      <div v-if="isMobile">
        <ion-content class="ion-padding"></ion-content>
        <ion-footer class="ion-no-border">
          <ion-toolbar>
            <a href="/module/badgeqr" class="btn btn-primary"><CIcon icon="cil-alarm" /></a>
            <a href="/dashboard" class="btn btn-primary"><CIcon icon="cil-home" /></a>
            <a href="/module/docs" class="btn btn-primary"><CIcon icon="cil-storage" /></a>
            <a href="/module/bacheca" class="btn btn-primary"><CIcon icon="cil-comment-square" /></a>
          </ion-toolbar>
        </ion-footer>
      </div>
      <div v-else>
        <ion-content class="ion-padding"></ion-content>
        <ion-footer class="ion-no-border">
          <ion-toolbar> 
            <a href="/module/badgeqr" class="btn btn-primary"><CIcon icon="cil-alarm" /></a>
            <a href="/dashboard" class="btn btn-primary"><CIcon icon="cil-home" /></a>
            <a href="/module/docs" class="btn btn-primary"><CIcon icon="cil-storage" /></a>
            <a href="/module/bacheca" class="btn btn-primary"><CIcon icon="cil-comment-square" /></a>
          </ion-toolbar>
        </ion-footer>
      </div>
    </div>
    
  </template>
  
<script>
  import { Device } from '@capacitor/device';
  
  export default {
    name: 'AppFooter',
    async mounted() {
      const info = await Device.getInfo();
  
      console.log("Device Info:", info);
  
      if (info.platform !== 'web') {
        // È un'app Capacitor (iOS o Android), quindi applichiamo il CSS
        document.body.classList.add('app-view-custom');
      }
    }
  }
</script>  

<style scoped>
  ion-button {
    --background: #97AF8F;
    --background-hover: #B0C2AB;
    --background-activated: #B0C2AB;
    --background-focused: #B0C2AB;

    --color: #1B1B1B;
  }
</style>